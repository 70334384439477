<template>
    <div>
        <div class="content-strategy-component">

            <div class="content-strategy-container">
                <MonthsList />

                <div class="btn-container">
                    <!-- Button to Add New -->
                    <div class="btn-primary" @click.prevent="startAddingNewNode">
                        <i class="fa-sharp fa-solid fa-circle-plus"></i>
                        Add New
                    </div>

                    <!-- Button to Approve Month -->
                    <div class="btn-save btn-second btn-approve-month" :class="{disabled: isApproveDisabled}" @click.prevent="approveMonth">
                        <i class="fa-solid fa-circle-check"></i>
                        Approve Month
                    </div>
                </div>

                <ContentStrategyCalendar v-if="getContentStrategy.isCalendarActive === true"/>
                <ContentStrategyTable v-if="getContentStrategy.isCalendarActive === false" />

            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import MonthsList from './MonthsList.vue';
import ContentStrategyTable from './ContentStrategyTable.vue';
import ContentStrategyCalendar from './ContentStrategyCalendar.vue';

export default {
    data() {
        return {
            showModal: false, // Modal control state
        };
    },
    components: {
        MonthsList,
        ContentStrategyTable,
        ContentStrategyCalendar
    },
    computed: {
        // Accessing Vuex getters
        ...mapGetters("client", ["getClientSlug", "getContentStrategy"]),

        // Compute the nodes from content strategy and ensure it defaults to an empty array
        nodes() {
            return this.getContentStrategy.nodes || [];
        },

        // Disable Approve Month button if all nodes are already approved
        isApproveDisabled() {
            return this.nodes.filter(node => !node.approved).length === 0;
        },
        isAddDisabled(){
            return this.getContentStrategy.isDayView;
        }
    },
    watch: {
        // Watching changes in getContentStrategy for debugging purposes
        getContentStrategy: {
            handler(val) {
                console.log('main :: getContentStrategy fields changed', val);
                // if(val.isShowing === false){
                //     this.$store.commit('client/setContentStrategy', { prop: 'isDayView', val: false });
                //     this.$store.commit('client/setContentStrategy', { prop: 'fullDate', val: '' });
                //     this.$store.commit('client/setContentStrategy', { prop: 'isCalendarActive', val: true });
                // }
            },
            deep: true,
        },
    },
    methods: {
        // Scroll to the active month in the months list
        scrollToActiveMonth() {
            const activeNode = document.querySelector('.months__month.month-active');
            const monthsContainer = document.querySelector('.months');
            if (activeNode && monthsContainer) {
                const containerRect = monthsContainer.getBoundingClientRect();
                const activeNodeRect = activeNode.getBoundingClientRect();
                const scrollLeft = monthsContainer.scrollLeft +
                    (activeNodeRect.left - containerRect.left) -
                    (monthsContainer.clientWidth / 2) +
                    (activeNode.clientWidth / 2);
                monthsContainer.scrollTo({ left: scrollLeft, behavior: 'smooth' });
            }
        },

        // Start adding a new node by updating Vuex state
        startAddingNewNode() {
            this.$store.commit('client/setContentStrategy', { prop: 'isAdding', val: true });
        },

        // Approve the entire month by setting isApprovingAll to true
        approveMonth() {
            this.$store.commit('client/setContentStrategy', { prop: 'isApprovingAll', val: true });
        },
    },
    async mounted() {
        try {
            // Fetch the client data based on the route parameter
            const clientParam = this.$route.params.client;
            await this.$store.dispatch('client/fetchClientData', clientParam);

            // Set the current month and year in Vuex
            const currentDate = new Date();
            const month = currentDate.toLocaleString('default', { month: 'long' }).toLowerCase();
            const year = currentDate.getFullYear();
            this.$store.commit('client/setContentStrategy', { prop: 'month', val: month });
            this.$store.commit('client/setContentStrategy', { prop: 'year', val: year });

            // Fetch content strategy nodes if month, year, and client slug are available
            if (this.getContentStrategy.month && this.getContentStrategy.year && this.getClientSlug) {
                await this.$store.dispatch('client/getAllContentStrategyNodes');
            }

            // Scroll to the active month in the list
            this.scrollToActiveMonth();

        } catch (error) {
            console.error('content strategy fetch client data error', error);
        }
    },
};
</script>

<style lang="scss">
@import "./../../assets/scss/components/content-strategy.scss";
</style>
