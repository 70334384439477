<template>
    <div class="content-strategy-calendar-view">
      <div class="calendar-wrap">
        <!-- Only render Qalendar if showCalendar is true -->
        <Qalendar
          v-if="showCalendar"
          :selected-date="dateSelected"
          :events="allNodes"
          :config="config"
          @event-was-clicked="onPostClick"
        />
        <h2 v-else>Waiting for Calendar</h2>
      </div>
    </div>
  </template>
  
  <script>
  import { Qalendar } from "qalendar";
  import { mapGetters } from "vuex";
  
  export default {
    components: {
      Qalendar,
    },
    computed: {
      ...mapGetters("client", ["getClientSlug", "getContentStrategy"]),
      
      // Computed property to format the selected date
      dateSelected() {
        const year = this.getContentStrategy.year;
        const month = this.getContentStrategy.month;
        return new Date(`${year}-${month}-01`);
      },
      allNodes(){
        let arr = [];
        console.log('all-nodes:', this.getContentStrategy.nodes);

        this.getContentStrategy.nodes.forEach(node => {
            const deadline = new Date(node.fieldGroup_Deadline.fields.deadline.value);

            // Formatting the date to 'YYYY-MM-DD hh:mm'
            const formattedDeadline = deadline.toLocaleString('en-CA', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                hour12: false
            }).replace(',',''); // Remove the comma between date and time

            arr.push({
                title: node.fieldGroup_Title.fields.title.value,
                time: { start: formattedDeadline, end: formattedDeadline }, // Use the formatted date here
                colorScheme: node.fieldGroup_Status.fields.status.value ? node.fieldGroup_Status.fields.status.value.toLowerCase() : 'no-value',
                isEditable: true,
                id: node.id,
                description: node.fieldGroup_Details.fields.details.value
            });
        });
        console.log('all-nodes-edited:', arr);
        return arr;
      }
    },
    methods:{    
            
        goToDayViewInit() {
        // Add event listener to the weekday elements after Qalendar is rendered
        this.$nextTick(() => {
            document.querySelectorAll('.calendar-month__weekday').forEach((element) => {
            element.addEventListener('click', (event) => {
              const id = event.currentTarget.id; // 'day-2024-09-16'

              // Extract the date part (2024-09-16)
              const datePart = id.split('-').slice(1).join('-');

              // Create a new Date object
              const dateObj = new Date(datePart);
              this.$store.commit('client/setContentStrategy', { prop: 'exactDate', val: dateObj });

              // Define options for formatting the weekday
              const optionsWeekday = { weekday: 'long' };

              // Define options for formatting the day with suffix
              const day = dateObj.getDate();
              const daySuffix = (day % 10 === 1 && day !== 11) ? 'st' :
                                (day % 10 === 2 && day !== 12) ? 'nd' :
                                (day % 10 === 3 && day !== 13) ? 'rd' : 'th';

              // Format the weekday
              const formattedWeekday = dateObj.toLocaleDateString('en-US', optionsWeekday);

              // Combine the weekday and day with the suffix
              const formattedDate = `${formattedWeekday} ${day}${daySuffix}`;

              const allNodes = this.getContentStrategy.nodes.filter(node => {
                  const nodeDate = new Date(node.fieldGroup_Deadline.fields.deadline.value);
                  return (
                      nodeDate.getFullYear() === dateObj.getFullYear() &&
                      nodeDate.getMonth() === dateObj.getMonth() &&
                      nodeDate.getDate() === dateObj.getDate()
                  );
              });

              this.$store.commit('client/setContentStrategy', { prop: 'nodes', val: allNodes });

              // Pass the formatted date into fullDate
              this.$store.commit('client/setContentStrategy', { prop: 'fullDate', val: formattedDate });
              this.$store.commit('client/setContentStrategy', { prop: 'isDayView', val: true });
              this.$store.commit('client/setContentStrategy', { prop: 'isCalendarActive', val: false });

          

            });
            });
        });
        },

    },
    mounted(){
        this.goToDayViewInit();
    },
    data() {
      return {
        showCalendar: true, 
        colors: {
            "title created": "rgb(255, 80, 162)",
            "assigned": "rgb(42, 187, 238)",
            "pending review": "rgb(255, 131, 68)",
            "approved": "rgb(68, 255, 196)",
            "rejected": "rgb(255, 0, 0)",
            "published": "rgb(24, 178, 140)",
            "archived": "rgb(79, 81, 112)",
            "no-value": "rgb(79, 81, 112)"
        },
        config: {
            defaultMode: "month",
            style: {
                colorSchemes: {
                'title created': {
                    color: '#FF0000',
                    backgroundColor: 'rgb(255, 80, 162)',
                },
                'assigned': {
                    color: '#fff',
                    backgroundColor: 'rgb(42, 187, 238)',
                },
                'pending review': {
                    color: '#fff',
                    backgroundColor: 'rgb(255, 131, 68)',
                },
                'approved': {
                    color: '#fff',
                    backgroundColor: 'rgb(68, 255, 196)',
                },
                'rejected': {
                    color: '#fff',
                    backgroundColor: 'rgb(255, 0, 0)',
                },
                'published': {
                    color: '#fff',
                    backgroundColor: 'rgb(24, 178, 140)',
                },
                'archived': {
                    color: '#fff',
                    backgroundColor: 'rgb(79, 81, 112)',
                },
                'no-value': {
                    color: '#fff',
                    backgroundColor: 'rgb(79, 81, 112)',
                }
                }
            },
        }
      };
    },
    watch: {
        showCalendar(val) {
            if (val) {
            this.$nextTick(() => {
            this.goToDayViewInit(); // Re-initialize event listeners when Qalendar is shown
            });
        }},
      // Watch for changes in year or month
      getContentStrategy: {
        handler() {
          // Destroy and rebuild the Qalendar component by toggling showCalendar
          this.showCalendar = false;
          this.$nextTick(() => {
            this.showCalendar = true; // Remount the component
          });
        },
        deep: true, // Watch for deep changes
      }
    }
  };
  </script>
  
  <style>
    @import "../../../node_modules/qalendar/dist/style.css";
  </style>
  
  <style lang="scss">
  .calendar-root-wrapper .calendar-root .calendar-month__weekday.is-today{
    border-radius: 0 !important;
  }
  .content-strategy-calendar-view{
    .calendar-header{
        display: none;
    }
    .calendar-month__week{
        min-height: 100px !important;
    }
    .calendar-month__event-title{
        font-weight: normal !important;
        font-size: 13px !important;
        margin-left: 5px !important
    }
    .calendar-month__week-day-name,
    .calendar-month__weekday{
        background: #131129 !important;
        border-radius: 0 !important;
    }
    .calendar-month__event-time{
        display: none !important;
    }
    .calendar-root {
        border-top: none !important;
    }
    .calendar-month__weekday{
        border-bottom: 1px solid rgba(255, 255, 255, 0.187);
        border-right: 1px solid rgba(255, 255, 255, 0.187);
        cursor: pointer;
        &:hover{
            background: #1d1a3e !important;
        }
    }
    .calendar-root .calendar-month .calendar-month__weeks:first-child{
        border-top: 1px solid red !important;
        //rgba(255, 255, 255, 0.187)
    }
    .calendar-month__week-day-name{
        padding-bottom: 20px !important;
        margin-bottom: 0 !important;
    }
    .calendar-month__event{
        pointer-events: none;
    }
  }
  </style>
  