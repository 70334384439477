<template>
    <div class="months-wrap" :class="{disabled : isMonthsDisabled}">
        <div class="months__year">
            <div class="styled-select" id="date">
                <VueNextSelect 
    :key="selectKey" 
    id="selectedDate" 
    @selected="setYear($event.date, 'year')" 
    :options="dates" 
    :model-value="2024"
    label-by="date" 
    close-on-select
    :placeholder="getContentStrategy.year"
/>

            </div>
        </div>
        <section>
        <div class="month-container">
            <div class="months">
                <ul class="months__list">
                    <li class="months__month" :class="{'month-active': getContentStrategy.month === 'january'}">
                        <span @click="setMonth('january')">Jan</span>
                    </li>
                    <li class="months__month" :class="{'month-active': getContentStrategy.month === 'february'}">
                        <span @click="setMonth('february')">Feb</span>
                    </li>
                    <li class="months__month" :class="{'month-active': getContentStrategy.month === 'march'}">
                        <span @click="setMonth('march')">Mar</span>
                    </li>
                    <li class="months__month" :class="{'month-active': getContentStrategy.month === 'april'}">
                        <span @click="setMonth('april')">Apr</span>
                    </li>
                    <li class="months__month" :class="{'month-active': getContentStrategy.month === 'may'}">
                        <span @click="setMonth('may')">May</span>
                    </li>
                    <li class="months__month" :class="{'month-active': getContentStrategy.month === 'june'}">
                        <span @click="setMonth('june')">Jun</span>
                    </li>
                    <li class="months__month" :class="{'month-active': getContentStrategy.month === 'july'}">
                        <span @click="setMonth('july')">Jul</span>
                    </li>
                    <li class="months__month" :class="{'month-active': getContentStrategy.month === 'august'}">
                        <span @click="setMonth('august')">Aug</span>
                    </li>
                    <li class="months__month" :class="{'month-active': getContentStrategy.month === 'september'}">
                        <span @click="setMonth('september')">Sep</span>
                    </li>
                    <li class="months__month" :class="{'month-active': getContentStrategy.month === 'october'}">
                        <span @click="setMonth('october')">Oct</span>
                    </li>
                    <li class="months__month" :class="{'month-active': getContentStrategy.month === 'november'}">
                        <span @click="setMonth('november')">Nov</span>
                    </li>
                    <li class="months__month" :class="{'month-active': getContentStrategy.month === 'december'}">
                        <span @click="setMonth('december')">Dec</span>
                    </li>
                </ul>
            </div>
        </div>
    </section>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    data() {
        return {
            selectKey: 0,
            dates: [{ date: "2024" }, { date: "2025" }, { date: "2026" }, { date: "2027" }, { date: "2028" }],
        };
    },
    computed: {
        year: {
            get() {
                return this.getContentStrategy.year;
            },
            set(val) {
                this.$store.commit("client/setContentStrategy", { prop: "year", val: val });
            }
        },
        isMonthsDisabled(){
            return this.getContentStrategy.isDayView;
        },
        ...mapGetters("client", ["getClientSlug", "getContentStrategy"]),
    },
    created() {
        // if (!this.getContentStrategy.year) {
        //     this.$store.commit("client/setContentStrategy", { prop: "year", val: "2024" });
        // }
    },
    methods: {
        setMonth(month) {
            this.$store.commit("client/setContentStrategy", { prop: "month", val: month });
            this.$store.dispatch('client/getAllContentStrategyNodes');
        },
        setYear(date) {
            this.$store.commit("client/setContentStrategy", { prop: "year", val: date });
            this.$store.dispatch('client/getAllContentStrategyNodes');
        },
    },
};
</script>

<style lang="scss">
	@import "./../../assets/scss/components/content-strategy.scss";
</style>

