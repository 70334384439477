<template>
    <div class="table-container">
        <div v-if="getContentStrategy.isDayView" class="content-strategy-back-calendar">
            <div  class="btn-close" @click.prevent="backToCalendar()">
                <i class="fa-solid fa-circle-arrow-left"></i>
                Back
            </div>
            <h2>Viewing {{ getContentStrategy.fullDate }}</h2>
        </div>
        <div class="table" v-if="getContentStrategy.nodes.length > 0">
            <div class="table__header">
                <span class="header-large">Title</span>
                <span class="header-large">Details</span>
                <span class="header-normal">Assign</span>
                <span class="header-normal">Deadline</span>
                <span class="header-normal">Keyword</span>
                <span class="header-normal">Format</span>
                <span class="header-normal">Intent</span>
                <span class="header-normal">Research</span>
                <span class="header-normal">Status</span>
                <span class="header-normal">Author</span>
                <span class="header-normal">Created</span>
            </div>

            <div v-if="getContentStrategy.nodes.length > 0">
                <div v-for="node in getContentStrategy.nodes" :key="node.id" class="table__content">
                    <div 
                        class="dot"
                        :style="{ backgroundColor: getNodeColor(node.fieldGroup_Status.fields.status.value) }">
                    </div>
                    <span class="header-large table-bright-text">{{ node.fieldGroup_Title.fields.title.value }}</span>
                    <span class="header-large table-text">{{ truncateText(node.fieldGroup_Details.fields.details.value, 50) }}</span>
                    <span class="header-normal table-text">{{ node.fieldGroup_Assign.fields.assign.value }}</span>
                    <span class="header-normal table-text">{{ formatDisplayDate(node.fieldGroup_Deadline.fields.deadline.value) }}</span>
                    <span class="header-normal table-text">{{ node.fieldGroup_Keyword.fields.keyword.value }}</span>
                    <span class="header-normal table-text">{{ node.fieldGroup_Format.fields.format.value }}</span>
                    <span class="header-normal table-text">{{ node.fieldGroup_Intent.fields.intent.value }}</span>
                    <span class="header-normal table-text">{{ node.fieldGroup_Research.fields.research.value }}</span>
                    <span class="header-normal table-text">{{ node.fieldGroup_Status.fields.status.value }}</span>
                    <span class="header-normal table-text">{{ node.fieldGroup_Author.fields.author.value }}</span>
                    <span class="header-normal table-text">{{ formatDisplayDate(node.fieldGroup_Created.fields.created.value) }}</span>
                    <div class="table__actions">
                        <div class="table-comment-wrap" @click.prevent="onComment(node.id)">
                            <div class="comment-count">{{ node.commentCount }}</div>
                            <div class="table-action-btn"><i class="fa-solid fa-comment"></i></div>
                        </div>
                        <div v-if="node.approved" class="table-action-btn" @click.prevent="onViewNode2(node.id)">
                            <i class="fa-solid fa-pen-to-square"></i>
                        </div>
                        <div v-if="node.approved === false" class="table-action-btn" @click.prevent="onEdit(node.id)">
                            <i class="fa-solid fa-pen-to-square"></i>
                        </div>
                        <div v-if="node.approved" class="table-action-btn table-action-btn-view" @click.prevent="onViewNode(node.id)">
                            <i class="fa-solid fa-arrow-right"></i>
                        </div>
                        <div v-else class="table-action-btn table-action-btn-approve" @click.prevent="onApprove(node.id)">
                            <i class="fa-solid fa-circle-check"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="no-nodes">
            <h2>Press 'Add New' to create nodes</h2>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from 'axios';

export default {
    computed: {
        ...mapGetters("client", ["getClientSlug", "getContentStrategy", 'getComments']),
    },
    methods: {
        truncateText(text, length) {
            if (text && text.length > length) {
                return text.substring(0, length) + '...';
            }
            return text;
        },
        getNodeColor(status) {
            console.log('getNodeColor', status);
            if(status){
                const data = status.toLowerCase();
                const colors = {
                    "title created": "rgb(255, 80, 162)",
                    "assigned": "rgb(42, 187, 238)",
                    "pending review": "rgb(255, 131, 68)",
                    "approved": "rgb(68, 255, 196)",
                    "rejected": "rgb(255, 0, 0)",
                    "published": "rgb(24, 178, 140)",
                    "archived": "rgb(79, 81, 112)"
                };
                return colors[data] || "rgb(79, 81, 112)"; 
            }else{
                return "rgb(79, 81, 112)";
            }
        },
        formatDisplayDate(date) {
            const newDate = new Date(date);
            console.log('new Date', newDate);
            if (isNaN(newDate)) return '';  // Handle invalid date
            const day = String(newDate.getDate()).padStart(2, '0');  // Get day and pad with zero if necessary
            const month = String(newDate.getMonth() + 1).padStart(2, '0');  // Get month (note: months are zero-indexed)
            const year = newDate.getFullYear();  // Get year
            return `${day}-${month}-${year}`;  // Format it to 'DD-MM-YYYY'
        },
        async onComment(id) {
            try {
                this.$store.commit('client/setContentStrategy', { prop: 'currentNode', val: id });
                const commentResponse = await axios.get(`/api/clients/getComments/${id}`);
                console.log('commentResponse', commentResponse);

                if (commentResponse.data) {
                    this.$store.commit('client/setClientNodeComments', commentResponse.data);
                } else {
                    this.$store.commit('client/setClientNodeComments', []);
                }
                this.$store.commit('client/setContentStrategy', { prop: 'isCommenting', val: true });
            } catch (error) {
                console.log('onComment catch error', error);
            }
        },
        onEdit(id) {
            this.$store.commit('client/setContentStrategy', { prop: 'currentNode', val: id });
            this.$store.commit('client/setContentStrategy', { prop: 'isEditing', val: true });
        },
        onApprove(id) {
            this.$store.commit('client/setContentStrategy', { prop: 'currentNode', val: id });
            this.$store.commit('client/setContentStrategy', { prop: 'isApproving', val: true });
        },
        async backToCalendar(){
            this.$store.commit('client/setContentStrategy', { prop: 'isDayView', val: false });
            this.$store.commit('client/setContentStrategy', { prop: 'fullDate', val: '' });
            this.$store.commit('client/setContentStrategy', { prop: 'isCalendarActive', val: true });
            await this.$store.dispatch('client/getAllContentStrategyNodes');
        },
        onViewNode(id) {
            this.$router.push({
                name: 'Client Content',
                query: {
                    format: 'false',
                    list: 'false',
                    node: id
                }
            });
        },
        onViewNode2(id) {
            this.$router.push({
                name: 'Client Content',
                query: {
                    format: 'false',
                    list: 'false',
                    node: id,
                    'load-editor': 'true'
                }
            });
        },
    }
};
</script>

<style lang="scss">
@import "./../../assets/scss/components/content-strategy.scss";
</style>
