<template>
    <div>
        <section class="g-main">
            <div class="content-main">
                <h1 style="color: white; font-size: 1.625rem;">Content Strategy</h1>
                <div v-if="getContentStrategy.isDayView === false">
                    <div v-if="getContentStrategy.isCalendarActive === false" class="btn-primary btn-format" @click.prevent="this.$store.commit('client/setContentStrategy', { prop: 'isCalendarActive', val: true });">
                        <i class="fa-solid fa-calendar-days"></i>
                        View Calendar
                    </div>
                    <div v-if="getContentStrategy.isCalendarActive === true" class="btn-primary btn-format" @click.prevent="this.$store.commit('client/setContentStrategy', { prop: 'isCalendarActive', val: false });">
                        <i class="fa-solid fa-list"></i>
                        View List
                    </div>
                </div>
            </div>
            <ContentStrategy />
        </section>
    </div>
</template>

<script>
import {mapGetters} from "vuex"
import ContentStrategy from "../../components/content-strategy/ContentStrategy.vue"

export default {
    data() {
        return {

        }
    },
    components: {
        ContentStrategy,
    },
    computed: {
        ...mapGetters("client", ["getClientSlug", "getContentStrategy"]),
        clientPagesLoaded: {
            get() {
				return this.$store.getters["client/getClientPagesLoaded"] || ""
            },
		},
    },
    watch: {
        deep: true
    },
    methods: {
        viewCalendar(){
            this.$store.commit('client/setContentStrategy', { prop: 'isCalendarActive', val: true });
        },
    },
    beforeUnmount() {
        // window.removeEventListener('scroll', this.handleScroll);
    },
    mounted() {
        this.$store.commit('client/setHidePageLoader', true);
        // window.addEventListener('scroll', this.handleScroll);
    },
}
</script>

<style lang="scss">
.content-main{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}
.btn-format{
    width: 190px !important;
    max-width: 190px !important;
}
</style>
